import { getText } from '@veraio/strank';
import { chatTypes } from 'enums';

export const actionsConfig = ({ isOwner, isAdmin, isSuperAdmin, isMember }) => ({
  [chatTypes.chat]: [],
  [chatTypes.group]: [
    !isOwner && isMember && {
      key: 'leave',
      label: getText('leave'),
      icon: 'exit_to_app',
    },
    isOwner && {
      key: 'delete',
      label: getText('delete'),
      icon: 'delete',
    },
  ].filter(Boolean),
  [chatTypes.channel]: [
    (isAdmin || isSuperAdmin) && {
      key: 'edit',
      label: getText('edit'),
      icon: 'edit',
    },
    isMember
      ? !isOwner && {
          key: 'leave',
          label: getText('leave'),
          icon: 'exit_to_app',
        }
      : {
          key: 'join',
          label: getText('join'),
          icon: 'exit_to_app',
        },
    isSuperAdmin
      ? {
          key: 'delete',
          label: getText('delete'),
          icon: 'delete',
        }
      : isMember &&
        isAdmin && {
          key: 'delete',
          label: getText('delete'),
          icon: 'delete',
        },
  ].filter(Boolean),
});
