import { keyframes } from '@emotion/react';

const circleAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const loader = (size, { fullScreen }) => [
  {
    width: size,
    height: size,
    position: 'absolute',
    top: `calc(50% - ${size / 2}px)`,
    left: `calc(50% - ${size / 2}px)`,
    zIndex: 1,

    '& .path': {
      transformOrigin: 'bottom center',
      ':nth-of-type(1)': {
        animation: `${circleAnimation} .5s 0.1s infinite ease-in`,
      },

      ':nth-of-type(2)': {
        animation: `${circleAnimation} .5s 0.2s infinite ease-in`,
      },

      ':nth-of-type(3)': {
        animation: `${circleAnimation} .5s 0.3s infinite ease-in`,
      },

      ':nth-of-type(4)': {
        animation: `${circleAnimation} .5s 0.4s infinite ease-in`,
      },
    },
  },
  fullScreen && {
    position: 'fixed',
  },
];

export const spinnerContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',

  '.spinner-text': {
    color: 'var(--gray-500)',
    marginTop: 48,
  },
};
