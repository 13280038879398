import { omit, useStore } from '@veraio/core';
import { createStore } from 'components';
import { chatTypes } from 'enums';
import { getTimestampFromUuidv7 } from 'utils';
import { getUserEmail } from './user';

const initialMessagesStore = {
  messages: {},
};

const messagesStore = createStore(initialMessagesStore);

export const cacheMessagesData = () => {
  const store = messagesStore.getState();
  localStorage.setItem('messages', JSON.stringify(store));
};

export const initializeMessages = () => {
  const storedData = localStorage.getItem('messages');
  if (!storedData) return;

  const cachedData = JSON.parse(storedData);

  messagesStore.setState(cachedData);
};

export const catchupMessages = (payload) => {
  const { messages } = messagesStore.getState();
  const newMessages = {};

  payload.chats?.length &&
    payload.chats.map((chat) => {
      const mappedMessages = (chat.messages || []).map((message) => ({
        ...message,
        targetId: chat.id,
        targetType: chatTypes.chat,
      }));

      const sortedMessages = [...mappedMessages, ...(messages[chat.id]?.messages ?? [])].sort((msgA, msgB) =>
        orderByTimestamp(msgA.messageId, msgB.messageId),
      );
      newMessages[chat.id] = {
        unreadCount: (messages[chat.id]?.unreadCount ?? 0) + mappedMessages.length,
        messages: sortedMessages,
      };
    });

  payload.groups?.length &&
    payload.groups.map((group) => {
      const mappedMessages = (group.messages || []).map((message) => ({
        ...message,
        targetId: group.id,
        targetType: chatTypes.group,
      }));
      const sortedMessages = [...(messages[group.id]?.messages ?? []), ...mappedMessages].sort((msgA, msgB) =>
        orderByTimestamp(msgA.messageId, msgB.messageId),
      );
      newMessages[group.id] = {
        unreadCount: (messages[group.id]?.unreadCount ?? 0) + group.messages.length,
        messages: sortedMessages,
      };
    });

  payload.channels?.length &&
    payload.channels.map((channel) => {
      const mappedMessages = (channel.messages || []).map((message) => ({
        ...message,
        targetId: channel.id,
        targetType: chatTypes.channel,
      }));
      const sortedMessages = [...(messages[channel.id]?.messages ?? []), ...mappedMessages].sort((msgA, msgB) =>
        orderByTimestamp(msgA.messageId, msgB.messageId),
      );
      newMessages[channel.id] = {
        unreadCount: (messages[channel.id]?.unreadCount ?? 0) + channel.messages.length,
        messages: sortedMessages,
      };
    });
  messagesStore.setState((prev) => ({
    messages: {
      ...prev.messages,
      ...newMessages,
    },
  }));
};

export const addMessage = (data) => {
  const { messages } = messagesStore.getState();

  const email = getUserEmail();
  const { targetId, author } = data;
  let unreadMessagesCount = messages[targetId]?.unreadCount ?? 0;

  if (author.email === email?.toLowerCase()) unreadMessagesCount = 0;
  else unreadMessagesCount++;

  const chatMessages = [data, ...(messages[targetId]?.messages ?? [])].sort((msgA, msgB) =>
    orderByTimestamp(msgA.messageId, msgB.messageId, false),
  );

  messagesStore.setState((prev) => ({
    ...prev,
    messages: {
      ...prev.messages,
      [targetId]: {
        unreadCount: unreadMessagesCount,
        messages: chatMessages,
      },
    },
  }));
};

export const resetUnreadMessages = (id) => {
  const { messages } = messagesStore.getState();
  const foundMessages = messages && messages[id];

  if (!foundMessages) return;

  messagesStore.setState((prev) => ({
    messages: {
      ...(prev?.messages ?? {}),
      [id]: {
        ...(prev?.messages ?? {})[id],
        unreadCount: 0,
      },
    },
  }));
};

export const setUnreadMessages = () => {};

export const updateMessage = () => {
  // MESSAGES: Should be implemented when we can get old messages
};

export const deleteMessages = (chatId) => {
  messagesStore.setState((prev) => ({
    ...omit(prev, chatId),
  }));
};

const orderByTimestamp = (uuidA, uuidB, asc) => {
  const timeA = uuidA ? getTimestampFromUuidv7(uuidA) : 0;
  const timeB = uuidB ? getTimestampFromUuidv7(uuidB) : 0;

  return asc ? timeA - timeB : timeB - timeA;
};
export const useMessagesStore = (...args) => useStore(messagesStore, ...args);
