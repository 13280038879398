import { useState, useRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { isFunction, isObjectLike, debounce, inRange, isNaN } from 'lodash-es';
import { uuid } from '../../utils';

const itemIdAttr = 'data-autocomplete-option-number';

export const useAutocomplete = (incomingProps, ref) => {
  const {
    value,
    options,
    getOptions,
    onInputChange,
    onBlur,
    onChange,
    displayKey = 'label',
    uniqueKey = 'value',
  } = incomingProps;

  const hasDynamicSuggestions = isFunction(getOptions);

  const [inputValue, setInputValue] = useState(value ?? '');
  const [suggestions, setSuggestions] = useState(options ?? []);
  const [isOpen, setIsOpen] = useState(false);
  const lastSearch = useRef(null);
  const rootNodeRef = useRef(null);
  const inputRef = useRef(null);
  const idRef = useRef(uuid());

  const performAsyncSearch = useCallback(
    debounce(async (newValue) => {
      const result = await getOptions(newValue);

      setSuggestions(result ?? []);
      setIsOpen(true);
    }, 700),
    [getOptions],
  );

  useImperativeHandle(ref, () => ({
    isOpen,
    suggestions,
    inputValue,
    rootNodeRef,
    inputRef,
    changeIsOpen: setIsOpen,
    changeSuggestions: setSuggestions,
    changeInputValue: setInputValue,
    triggerNewSearch: (newVal) => performSearch(newVal),
  }));

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  useEffect(() => {
    performSearch(inputValue);
  }, [options]);

  const handleChange = ({ target: { value: input } }) => {
    setInputValue(input);
    isFunction(onInputChange) && onInputChange(input.trim());
    performSearch(input.trim());
  };

  const handleBlur = (event) => {
    isFunction(onBlur) && onBlur(inputValue);
    const { relatedTarget, target } = event ?? {};

    if (!target?.parentNode?.parentNode?.contains(relatedTarget)) setIsOpen(false);
  };

  const handleSelect = (item) => {
    setTimeout(() => {
      setInputValue(isObjectLike(item) ? item[displayKey] : item);
      isFunction(onChange) && onChange(item);
    });
  };

  const handleInputKeyDown = (e) => {
    if (suggestions.length && (e.keyCode === 40 || e.keyCode === 38)) {
      const nextIndex = e.keyCode === 40 ? 0 : suggestions.length - 1;
      document.querySelector(`[${itemIdAttr}='${nextIndex}']`)?.focus();
    }
    if (e.keyCode === 27) e.target.blur();
    if (e.keyCode === 13) isFunction(onChange) && onChange(inputValue);
  };

  const handleItemKeyDown = (e) => {
    const { target } = e;

    if (e.keyCode === 40 || e.keyCode === 38) {
      const maxElement = suggestions.length - 1;
      const activeNode = target;
      const currentIndex = Number(activeNode.getAttribute(itemIdAttr));
      if (isNaN(currentIndex)) return;
      const nextIndex = currentIndex + (e.keyCode === 40 ? 1 : -1);
      inRange(nextIndex, maxElement + 1)
        ? document.querySelector(`[${itemIdAttr}='${nextIndex}']`)?.focus()
        : inputRef.current.focus();
    }

    if (e.keyCode === 27) target.blur();

    if (e.keyCode === 13) handleSelect(suggestions[Number(target.getAttribute(itemIdAttr))]);
  };

  const performSearch = (newValue) => {
    if (hasDynamicSuggestions) {
      if (lastSearch.current === newValue) return;
      if (newValue?.length < 3) return;
      lastSearch.current = newValue;

      newValue !== inputValue && performAsyncSearch(newValue);
      return;
    }

    const suggestionsList = options?.filter((el) =>
      String(isObjectLike(el) ? el[uniqueKey] ?? el[displayKey] : el)
        .toLowerCase()
        .includes(newValue.toLowerCase()),
    );

    return setSuggestions(suggestionsList ?? []);
  };

  const handleDelete = () => {
    setInputValue('');
    setIsOpen(false);
  };

  return {
    inputProps: {
      id: idRef.current,
      ref: inputRef,
      value: inputValue || '',
      placeholder: incomingProps.placeholder,
      onFocus: () => setIsOpen(true),
      onChange: handleChange,
      onDelete: handleDelete,
      onKeyDown: handleInputKeyDown,
      onBlur: handleBlur,
      maxLength: '2048',
      type: 'text',
      spellCheck: 'false',
      'aria-autocomplete': 'both',
      'aria-haspopup': 'false',
      autoCapitalize: 'off',
      autoComplete: 'off',
      autoCorrect: 'off',
    },
    suggestionItemProps: {
      itemIdAttr,
      onKeyDown: handleItemKeyDown,
      onClick: handleSelect,
    },
    isOpen,
    suggestions,
    rootNodeRef,
  };
};
