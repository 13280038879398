import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';
import { setCurrentChat, useChatStore, useMessagesStore, useUserStore } from 'stores';
import {
  Avatar,
  Button,
  ChatActions,
  ChatInfo,
  ConfirmationModal,
  ConversationContainer,
  Icon,
  SendMessage,
} from 'components';
import { chatContainer, chatHeaderContainer } from './styles';
import { USER_CHAT_STATUS, userChatStatusMap, chatTypes } from 'enums';
import { getFirstSymbol, getTimeFromNow, getUserDisplayName } from 'utils';
import { joinChannel, sendChatMessage } from 'services';

const Chat = ({ styles }) => {
  const { getText } = useTranslations();
  const { currentChat } = useChatStore();
  const { messages } = useMessagesStore();
  const { email } = useUserStore();
  const [myCurrentChat, setMyCurrentChat] = useState(null);
  const [openContainer, setOpenContainer] = useState({ chatInfo: false });
  const [openModal, setOpenModal] = useState({ join: false });
  const isAgentModAdmin = hasAtLeastOneRole([
    USER_ROLES.CHAT_SUPER_ADMIN,
    USER_ROLES.CHAT_ADMIN,
    USER_ROLES.CHAT_MODERATOR,
  ]);

  useEffect(() => {
    setMyCurrentChat(currentChat);
  }, [currentChat]);

  const handleSearch = (val) => console.log('Search for:', val);

  const handleToggleChatInfo = () => setOpenContainer((prev) => ({ ...prev, chatInfo: !prev.chatInfo }));

  const closeChat = () => setCurrentChat(null);

  const handleSendMessage = (message) => {
    if (message.text && !message.text.length) return;

    const messageData = {
      content: message.text,
      targetType: myCurrentChat.targetType,
      targetId: myCurrentChat.id,
    };

    sendChatMessage(messageData);
  };

  const handleJoinPublicChannel = () => joinChannel(myCurrentChat.id, handleCloseModal);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenModal({ join: true });
  };

  const handleCloseModal = () => setOpenModal({});

  return (
    <div css={chatContainer(styles, !!myCurrentChat?.id)}>
      {myCurrentChat?.id ? (
        <>
          <div css={chatHeaderContainer(userChatStatusMap[myCurrentChat.user?.status]?.color)}>
            <div className="chat-title">
              <Icon iconName="chevron_left" onClick={closeChat} className="go-back-icon" />
              {myCurrentChat.targetType === chatTypes.group ||
              (myCurrentChat.targetType === chatTypes.channel && !myCurrentChat.icon) ? (
                <div className="letter-avatar">
                  <div>{getFirstSymbol(myCurrentChat.name, myCurrentChat.targetType[0])}</div>
                </div>
              ) : myCurrentChat.targetType === chatTypes.channel ? (
                <Avatar picThumbnailUrl={myCurrentChat.icon} />
              ) : (
                <Avatar picThumbnailUrl={myCurrentChat.user?.pictureUrl} />
              )}
              <div className="chat-name" role="button" tabIndex={0} onClick={handleToggleChatInfo}>
                {myCurrentChat?.name ?? getUserDisplayName(myCurrentChat?.user)}
              </div>
              {myCurrentChat.targetType === chatTypes.chat && (
                <>
                  {myCurrentChat?.user && <div className="status-indicator" />}
                  <div className="last-active-time">
                    {myCurrentChat.status === USER_CHAT_STATUS.Offline.toLowerCase() &&
                      getTimeFromNow(myCurrentChat?.lastActive)}
                  </div>
                </>
              )}
            </div>
            <ChatActions chat={myCurrentChat} onOpenChatInfo={handleToggleChatInfo} onSearch={handleSearch} />
          </div>

          <ConversationContainer data={messages ? messages[myCurrentChat?.id] : {}} />

          {myCurrentChat?.targetType === chatTypes.channel &&
          !myCurrentChat?.users?.find((member) => (member.email === email?.toLowerCase())) &&
          myCurrentChat?.owner?.email !== email?.toLowerCase() ? (
            <div className="restricted-chat-footer">
              <Button small type="default" onClick={handleOpenModal}>
                {getText('joinChat')}
              </Button>
            </div>
          ) : !isAgentModAdmin && myCurrentChat?.targetType === chatTypes.channel && myCurrentChat?.readOnly ? (
            <div className="restricted-chat-footer">
              <p>{getText('readOnlyChat')}</p>
            </div>
          ) : (
            <SendMessage onSend={handleSendMessage} />
          )}
        </>
      ) : (
        <div className="no-chat-container">
          <Icon iconName="sms" className="no-chat-icon" />
          <p className="welcome-text">{getText('welcome')}</p>
          <p className="secondary-text">{getText('findContactsGroupsChannels')}</p>
          <p className="secondary-text">{getText('connectWithMembersWorldwide')}</p>
        </div>
      )}
      {openContainer.chatInfo && (
        <div className="chat-info-container">
          <ChatInfo onClose={handleToggleChatInfo} />
        </div>
      )}
      <ConfirmationModal
        showModal={openModal.join}
        title={getText('joinChannel')}
        onOk={handleJoinPublicChannel}
        onCancel={handleCloseModal}>
        {getText('confirmJoinChannel')}
      </ConfirmationModal>
    </div>
  );
};

export default Chat;
