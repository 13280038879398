import { useRef, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components/ui';
import { ActionsContainer, FileBox, ImageBox, TextWithDotsAnimation } from 'components/shared';
import { displayTime, parseUuid7ToDate, copyToClipboard } from 'utils';
import { messageContainer } from './styles';

const MessageBox = (props) => {
  const { message, isRight, actionsPosition } = props;
  const { getText } = useTranslations();
  const [openContainer, setOpenContainer] = useState({ messageActions: false });
  const messageRef = useRef();
  const [actionsOnTop, setActionsOnTop] = useState(false);

  const toggleMoreContainer = (e) => {
    e.stopPropagation();
    const position = messageRef.current?.getBoundingClientRect();
    setActionsOnTop(position?.top + 300 >= window.innerHeight);
    setOpenContainer((prev) => ({ messageActions: !prev.messageActions }));
  };

  const handleCopyText = (e) => {
    e.stopPropagation();
    copyToClipboard(message.content, 'Successfully copied text');
  };

  const handleCloseContainer = () => setOpenContainer({});

  return (
    <>
      <div css={messageContainer(isRight)}>
        <div className="message-body">
          {message.typing ? (
            <TextWithDotsAnimation text={getText('typing')} type={isRight ? 'primary' : 'secondary'} />
          ) : (
            <>
              <div className="message">{message.content}</div>
              {message.images?.map((image) => (
                <ImageBox key={`image-${image.id}`} image={image} isRight={isRight} />
              ))}
              {message.files?.map((file) => (
                <FileBox key={`file-${file.id}`} file={file} isRight={isRight} />
              ))}
              <div className="time-container">
                <Icon iconName="schedule" size={13} className="time-icon" />
                <span>{message.messageId && displayTime(parseUuid7ToDate(message.messageId))}</span>
              </div>
            </>
          )}
        </div>
        {!message.typing && (
          <div className="action-buttons" ref={messageRef}>
            <Icon iconName="more_vert" size={18} className="more-icon" onClick={toggleMoreContainer} />
            {openContainer.messageActions && (
              <ActionsContainer
                styles={{
                  top: actionsOnTop ? 'auto' : 25,
                  bottom: actionsOnTop ? 85 : 'auto',
                  right: isRight && 0,
                  width: 150,
                  ...(actionsPosition ?? {}),
                }}
                onBlur={handleCloseContainer}>
                <div role="button" tabIndex={0} onClick={handleCopyText} className="item">
                  <p className="name">{getText('copy')}</p>
                  <Icon iconName="content_copy" size={16} />
                </div>
                {/* WAIT FOR BE */}
                {/* <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('edit')}</p>
                  <Icon iconName="edit" size={16} />
                </div>
                <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('delete')}</p>
                  <Icon iconName="delete" size={16} />
                </div> */}
              </ActionsContainer>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MessageBox;
