export const usersSearchContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2rem',
  marginTop: '1rem',

  label: {
    color: 'var(--secondary)',
  },

  p: {
    fontSize: 10,
  },

  '.suggestion': {
    justifyContent: 'space-between',
    '.user-info': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
  },

  '.users-container': {
    marginTop: '0.5rem',

    '.users-title': {
      fontWeight: 600,
      backgroundColor: 'var(--background-strong)',
      padding: '1rem 1.5rem',
    },

    '.users-body': {
      maxHeight: '160px',
      overflow: 'auto',
      padding: '1rem',
      backgroundColor: 'var(--theme-color)',

      '.letter': {
        color: 'var(--primary)',
        fontSize: 16,
        fontWeight: 500,
        margin: '0.5em 0',
        padding: '1.2rem',
      },

      '.users-list': {
        paddingLeft: '1rem',
        '.user': {
          padding: '1rem',
          display: 'flex',
          '.check-user': {
            '.label': {
              fontSize: 14,
              fontWeight: 600,
            },
          },
        },
      },
      '.no-data-text': {
        color: 'var(--secondary)',
        textAlign: 'center',
      },
    },
  },
};
