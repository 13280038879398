export const chatsContainer = {
  position: 'relative',
  overflow: 'hidden',
  height: '100vh',
  padding: 12,
  backgroundColor: 'var(--background-stronger)',
  minWidth: 380,
  maxWidth: 380,

  '@media (max-width: 991px)': {
    minWidth: '100%',
    maxWidth: '100%',
  },

  '.page-title': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
  },

  '.conversations-container': {
    marginTop: '2.15rem',

    h5: {
      marginLeft: 6,
      marginBottom: 12,
    },

    ul: {
      overflow: 'overlay',
      maxHeight: 'calc(100vh - 185px)',
    },

    '.no-data': {
      padding: 18,
      textAlign: 'center',
      color: 'var(--secondary-weaker)',
      fontWeight: 500,
    },
  },
};
