import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isEmpty, isFunction, omit } from '@veraio/core';
import { CheckButton, Collapse, Icon, SearchBar, showError, Spinner } from 'components/ui';
import { getUserDisplayName, orderUsersByLetter } from 'utils';
import { searchUsers } from 'services';
import Avatar from '../Avatar';
import { usersSearchContainer } from './styles';

const UsersSearch = (props) => {
  const { users, resetUsersList, filterUsers, onChangeSelection } = props;
  const { getText } = useTranslations();
  const [addedUsers, setAddedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contactsByLetter, setContactsByLetter] = useState({});

  useEffect(() => {
    getContactsByLetter();
  }, [addedUsers]);

  useEffect(() => {
    if (resetUsersList) {
      setAddedUsers([]);
      setContactsByLetter({});
    }
  }, [resetUsersList]);

  const getContactsByLetter = async () => {
    const orderedContacts = orderUsersByLetter(addedUsers);
    setContactsByLetter(orderedContacts);
  };

  const handleSearchUsers = async (val) => {
    // Ignore short or empty searches
    if (!val || val.length < 3) return;

    setIsLoading(true);
    const [res, err] = await searchUsers(val);

    if (err) {
      setIsLoading(false);
      showError(err);
      return;
    }

    setIsLoading(false);
    return isFunction(filterUsers) ? filterUsers(res) : res;
  };

  const handleDelete = (options) => options.inputProps.onDelete && options.inputProps.onDelete();

  const handleSelect = (isChecked, selectedUserId) => {
    const updatedUsers = isChecked
      ? [...(users ?? []), selectedUserId]
      : (users ?? []).filter((userId) => userId !== selectedUserId) ?? [];

    onChangeSelection(updatedUsers);
  };

  return (
    <div css={usersSearchContainer}>
      <SearchBar
        className="search-bar"
        placeholder="Search"
        displayKey={'firstName'}
        noDataText={getText('noUsersFound')}
        getOptions={handleSearchUsers}
        laoding={isLoading}
        renderSuggestion={(val) => (
          <div
            className="suggestion"
            {...val?.itemProps}
            role={val?.itemProps?.role}
            onClick={() => {
              handleSelect(!users?.includes(val.item.id), val.item.id);

              !addedUsers.find((user) => user?.id === val.item.id) &&
                setAddedUsers((prev) => [...(prev ?? []), val.item]);

              val.suggestionItemProps.onClick(val?.item);
            }}>
            <div className="user-info">
              <Avatar pictureUrl={val?.item?.pictureUrl} status={val?.item?.status} width={30} />
              {getUserDisplayName(val?.item)}
            </div>
            {!!users?.includes(val.item.id) && <Icon iconName="check" />}
          </div>
        )}
        renderInput={(val) => (
          <div {...val.containerProps}>
            <label htmlFor={val.inputProps.id}>
              <Icon {...val.iconProps} />
            </label>
            <input {...omit(val.inputProps, ['onDelete'])} />
            <label htmlFor={val.inputProps.id}>
              <Spinner size={20} loading={isLoading} />
            </label>
            <label htmlFor={val.inputProps.id}>
              <Icon material size={20} iconName="close" css={val.iconProps.css} onClick={() => handleDelete(val)} />
            </label>
          </div>
        )}
      />
      <div className="users-container">
        <Collapse header={`${getText('selectedUsers')}: ${users?.length ?? 0}`}>
          <div className="users-body">
            {!isEmpty(contactsByLetter) ? (
              Object.keys(contactsByLetter).map((key) => (
                <div key={`letter-${key}`}>
                  <div className="letter">{key}</div>
                  <ul className="users-list">
                    {contactsByLetter[key].map((contact) => (
                      <li key={`contact-${contact.id}`} className="user">
                        <CheckButton
                          className="check-user"
                          onChange={(value) => handleSelect(value, contact.id)}
                          value={!!(users ?? []).includes(contact.id)}>
                          {getUserDisplayName(contact)}
                        </CheckButton>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <div className="no-data-text">{getText('searchForUsersToAdd')}</div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

UsersSearch.propTypes = {
  users: PropTypes.array,
  resetUsersList: PropTypes.bool,
  filterUsers: PropTypes.func,
  onChangeSelection: PropTypes.func,
};

export default UsersSearch;
