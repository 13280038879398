/* eslint-disable camelcase */
import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';
import { postReq } from 'services/axios';

// Update user presence status
export const updatePresence = (status, callback) => sendMessage(emitters.updatePresence, { status }, callback);

export const searchUsers = async (searchText) => {

  const [res, err] = await postReq(`${apiUrls.peekcloakApiUrl}/search`, {
    query: searchText,
  });
  return [res, err];
};
