import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { SearchInput } from 'components/shared';
import { ConversationListItem, CreateGroupModal } from 'components/screens';
import { setCurrentChat, useChatStore, resetUnreadMessages, useMessagesStore } from 'stores';
import { getTimestampFromUuidv7 } from 'utils';
import { chatsContainer } from './styles';
import { Icon, showSuccess } from 'components';
import { createGroup, searchUsers } from 'services';

const Chats = () => {
  const { getText } = useTranslations();
  const { chats, groups, currentChat } = useChatStore();
  const { messages } = useMessagesStore();
  const [recentChats, setRecentChats] = useState([]);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();
  const [openGroupModal, setOpenGroupModal] = useState(false);

  useEffect(() => {
    getRecentChats();
  }, [chats, groups]);

  useEffect(() => {
    setRecentChats((prev) => sortChatsByLastMessage(prev));
  }, [messages]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const handleSearch = (val) => {
    setSearchText(val);
    searchUsers(val);
    getRecentChats({ search: val });
  };

  const filterChatsByTextAndMessages = (text) => {
    const allChats = [...(chats ?? []).filter((el) => !!messages[el.id]?.messages?.length), ...(groups ?? [])];

    return text
      ? allChats.filter((el) =>
          el.user
            ? el.user.email?.toLowerCase() === text.toLowerCase() ||
              el.user.firstName?.toLowerCase()?.includes(text.toLowerCase()) ||
              el.user.lastName?.toLowerCase()?.includes(text.toLowerCase())
            : (el.name && el.name.toLowerCase().includes(text.toLowerCase())) ||
              el.users?.find((member) => member.email?.toLowerCase() === text.toLowerCase()),
        )
      : allChats;
  };

  const sortChatsByLastMessage = (chatsArray) => {
    return chatsArray.sort((chatA, chatB) => {
      // Groups can be without messages
      if (!(messages[chatA.id]?.messages ?? [])[0]) return 1;
      if (!(messages[chatB.id]?.messages ?? [])[0]) return -1;

      const chatALastMessage = messages[chatA.id]?.messages[0] ?? {};
      const chatBLastMessage = messages[chatB.id]?.messages[0] ?? {};

      const timeA = chatALastMessage.messageId && getTimestampFromUuidv7(chatALastMessage.messageId);
      const timeB = chatBLastMessage.messageId && getTimestampFromUuidv7(chatBLastMessage.messageId);

      return timeB - timeA;
    });
  };

  const getRecentChats = (options) => {
    const search = options?.search ?? searchText;
    const filteredChats = filterChatsByTextAndMessages(search);
    const sortedChats = sortChatsByLastMessage(filteredChats);

    setRecentChats(sortedChats);
  };

  const handleOpenChat = (id, targetType) => {
    setCurrentChat(id, targetType);
    resetUnreadMessages(id);
  };

  const handleOpenGroupModal = () => setOpenGroupModal(true);

  const handleCloseGroupModal = () => setOpenGroupModal(false);

  const handleCreateGroup = (data, resetForm) => {
    createGroup(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a group');
      handleCloseGroupModal();
    });
  };

  return (
    <>
      <div css={chatsContainer}>
        <div className="page-title">
          <h4>{getText('chats')}</h4>
          <Icon iconName="group_add" color="secondary" onClick={handleOpenGroupModal} />
        </div>

        <SearchInput placeholder={getText('searchChats')} onChange={handleSearch} />

        <div className="conversations-container">
          {recentChats && recentChats.length ? (
            <>
              <h5>{getText('recent')}</h5>
              <ul>
                {recentChats?.map((chat) => {
                  return (
                    <ConversationListItem
                      key={chat.id}
                      chat={chat}
                      isSelected={openedChatId === chat.id}
                      onChatOpen={handleOpenChat}
                    />
                  );
                })}
              </ul>
            </>
          ) : (
            <div className="no-data">{getText('noChatsFound')}</div>
          )}
        </div>
      </div>
      <CreateGroupModal showModal={openGroupModal} onCreate={handleCreateGroup} onClose={handleCloseGroupModal} />
    </>
  );
};

export default Chats;
