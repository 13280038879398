import { useStore, createStore } from 'components';
import { chatTypes } from 'enums/chat';
import { getUserEmail } from './user';
import { getTimestampFromUuidv7 } from 'utils';

const initialChatStore = {
  currentChat: null,
  chats: [],
  groups: [],
  channels: [],
  otherChannels: [],
  contacts: [],
};

const chatStore = createStore(initialChatStore);

export const cacheChatsData = () => {
  const store = chatStore.getState();
  localStorage.setItem('chats', JSON.stringify(store));
};

export const initializeChats = (payload) => {
  if (!payload) {
    const storedData = localStorage.getItem('chats');
    if (!storedData) return;

    const cachedStore = JSON.parse(storedData);
    return chatStore.setState(cachedStore);
  }

  const sortedChats = (payload.chats ?? [])
    .map((chat) => ({ ...chat, targetType: chatTypes.chat }))
    .sort((chatA, chatB) => orderByTimestamp(chatA.id, chatB.id));

  const sortedGroups = (payload.groups ?? [])
    .map((group) => ({ ...group, targetType: chatTypes.group }))
    .sort((groupA, groupB) => orderByTimestamp(groupA.id, groupB.id));

  // Private and public channels, in which the current user is a member
  const sortedChannels = sortChannels(payload.channels)?.map((channel) => ({
    ...channel,
    targetType: chatTypes.channel,
  }));

  // Public channels, in which the current user is NOT a member, but he/she can join
  const sortedOtherChannels = (payload.public_channels ?? [])
    .map((channel) => ({ ...channel, targetType: chatTypes.channel }))
    .sort((channelA, channelB) => orderByTimestamp(channelA.id, channelB.id));

  chatStore.setState((prev) => ({
    ...prev,
    // currentChat: currentChat ? { ...currentChat } : null,
    chats: sortedChats,
    groups: sortedGroups,
    channels: sortedChannels,
    otherChannels: sortedOtherChannels,
  }));
};

// Add new direct chat to storage.
// Update current chat if the current user has opened this chat
export const addChat = (payload) => {
  const { currentChat } = chatStore.getState();
  const chatToAdd = {
    ...payload,
    targetType: chatTypes.chat,
  };

  chatStore.setState((prev) => ({
    ...prev,
    chats: [...(prev.chats ?? []), chatToAdd],
    currentChat: currentChat.id === payload.user.id ? chatToAdd : prev.currentChat,
  }));
};

export const addChannel = (data) => {
  const { channels, otherChannels } = chatStore.getState();
  const email = getUserEmail();

  if (channels.find((channel) => channel.id === data.id) || otherChannels.find((channel) => channel.id === data.id))
    return;

  const isMember = data.users.find((user) => user.email === email?.toLowerCase());
  const isOwner = data.owner.email === email?.toLowerCase();

  const channelToAdd = { ...data, targetType: chatTypes.channel };
  chatStore.setState((prev) => ({
    ...prev,
    channels: isMember ? sortChannels([channelToAdd, ...(prev.channels ?? [])]) : prev.channels,
    otherChannels: !isMember ? [channelToAdd, ...(prev.otherChannels ?? [])] : prev.otherChannels,
    currentChat: isOwner ? channelToAdd : prev.currentChat,
  }));
};

// When you leave or join a public channel, you should move the channel in the corresponding collection
// If you LEAVE the channel (toMyChannels === false), the channel will go from MY channels to OTHER channels
// If you JOIN the channel (toMyChannels === true), the channel will go from OTHER channels to MY channels
export const movePublicChannel = (data, toMyChannels) => {
  const { channels, otherChannels } = chatStore.getState();
  const foundChannel = (toMyChannels ? otherChannels : channels).find((channel) => channel.id === data.id);
  if (!foundChannel) return;

  foundChannel.users = toMyChannels
    ? [...foundChannel.users, data.user]
    : foundChannel.users.filter((channelUser) => channelUser !== data.user);

  chatStore.setState((prev) => ({
    ...prev,
    channels: toMyChannels
      ? sortChannels([...(prev.channels ?? []), foundChannel])
      : prev.channels.filter((ch) => ch.id !== data.id),
    otherChannels: !toMyChannels
      ? sortChannels([...(prev.otherChannels ?? []), foundChannel])
      : prev.otherChannels.filter((ch) => ch.id !== data.id),
    currentChat: data.id === prev.currentChat?.id ? { ...foundChannel } : prev.currentChat,
  }));
};

export const addGroup = (data) => {
  const { groups } = chatStore.getState();
  const email = getUserEmail();

  if (groups.find((group) => group.id === data.id)) return;

  const isOwner = data.owner.email === email?.toLowerCase();

  const groupToAdd = { ...data, targetType: chatTypes.group };
  chatStore.setState((prev) => ({
    ...prev,
    groups: [groupToAdd, ...(prev.groups ?? [])],
    currentChat: isOwner ? groupToAdd : prev.currentChat,
  }));
};

export const getChannelById = (id) => {
  const { channels, otherChannels } = chatStore.getState();
  return channels.find((ch) => ch.id === id) || otherChannels.find((ch) => ch.id === id) || {};
};

export const setCurrentChat = (id, targetType) => {
  const { currentChat, chats, groups, channels, otherChannels } = chatStore.getState();

  if (!id) return chatStore.setState((prev) => ({ ...prev, currentChat: null }));

  if (currentChat?.id === id) return;

  let chatToOpen = null;
  switch (targetType) {
    case chatTypes.chat: {
      chatToOpen = chats.find((chat) => chat.id === id || chat.user.id === id) ?? {
        id,
        user: { id },
        targetType: chatTypes.chat,
      };
      break;
    }
    case chatTypes.group: {
      chatToOpen = groups.find((group) => group.id === id);
      break;
    }
    case chatTypes.channel: {
      chatToOpen = channels.find((channel) => channel.id === id) ?? otherChannels.find((channel) => channel.id === id);
      break;
    }
  }

  chatStore.setState((prev) => ({ ...prev, currentChat: chatToOpen ? { ...chatToOpen } : null }));
};

export const addUsersToChannelStore = (data) => {
  const { channels = [], otherChannels = [], currentChat } = chatStore.getState();
  const email = getUserEmail();
  const foundChannel = {
    ...(channels.find((channel) => channel.id === data.id) ??
      otherChannels.find((channel) => channel.id === data.id) ??
      {}),
  };

  if (!foundChannel) return;
  const isCurrent = currentChat?.id === data.id;
  const usersToAdd = data.users.filter(
    (user) => !foundChannel?.users?.find((channelUser) => channelUser.id === user.id),
  );
  const isMember = foundChannel.users.find((channelUser) => channelUser.email === email?.toLowerCase());

  const updatedChannels = (isMember ? channels : otherChannels).map((channel) => {
    if (channel.id === foundChannel.id) channel.users = [...channel.users, ...usersToAdd];

    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: isMember ? updatedChannels : prev.channels,
    otherChannels: !isMember ? updatedChannels : prev.otherChannels,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...foundChannel.users, ...usersToAdd] } : prev.currentChat,
  }));
};

export const addUsersToGroupStore = (data) => {
  const { groups, currentChat } = chatStore.getState();
  const foundGroup = groups.find((group) => group.id === data.id);
  if (!foundGroup) return;
  const isCurrent = currentChat?.id === data.id;

  const usersToAdd = data.users.filter((user) => !currentChat?.users?.find((groupUser) => groupUser.id === user.id));

  const updatedGroups = groups.map((group) => {
    if (group.id === foundGroup.id) group.users = [...group.users, ...usersToAdd];

    return group;
  });

  chatStore.setState((prev) => ({
    ...prev,
    groups: updatedGroups,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...foundGroup.users, ...usersToAdd] } : prev.currentChat,
  }));
};

export const removeUsersFromGroupStore = (data) => {
  const { groups, currentChat } = chatStore.getState();
  const foundGroup = groups.find((group) => group.id === data.id);
  if (!foundGroup) return;

  const isCurrent = currentChat?.id === data.id;
  const updatedUsers =
    foundGroup.users?.filter((groupUser) => !data.users.find((user) => user.id === groupUser.id)) ?? [];

  const updatedGroups = groups.map((group) => {
    if (group.id === foundGroup.id) group.users = updatedUsers;

    return group;
  });

  chatStore.setState((prev) => ({
    ...prev,
    groups: updatedGroups,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...updatedUsers] } : prev.currentChat,
  }));
};

export const removeUsersFromChannelStore = (data) => {
  const { channels, currentChat } = chatStore.getState();
  const foundChannel = channels.find((channel) => channel.id === data.id);
  if (!foundChannel) return;

  const isCurrent = currentChat?.id === data.id;
  const updatedUsers =
    foundChannel.users?.filter((channelUser) => !data.users.find((user) => user.id === channelUser.id)) ?? [];

  const updatedChannels = channels.map((channel) => {
    if (channel.id === foundChannel.id) channel.users = updatedUsers;

    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: updatedChannels,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...updatedUsers] } : prev.currentChat,
  }));
};

export const removeGroup = (id) => {
  const { groups, currentChat } = chatStore.getState();
  const isCurrent = id === currentChat?.id;

  chatStore.setState((prev) => ({
    ...prev,
    groups: groups.filter((group) => group.id !== id),
    currentChat: isCurrent ? null : prev.currentChat,
  }));
};

export const removeChannel = (id) => {
  const { channels = [], otherChannels = [], currentChat = {} } = chatStore.getState();
  const isCurrent = id === currentChat.id;

  chatStore.setState((prev) => ({
    ...prev,
    channels: channels.filter((channel) => channel.id !== id),
    otherChannels: otherChannels.filter((channel) => channel.id !== id),
    currentChat: isCurrent ? null : prev.currentChat,
  }));
};

// ROLES: Can admin update a channel, in which he doesn't participate?
export const editChannel = (data) => {
  const { channels, currentChat } = chatStore.getState();
  const foundChannel = channels.find((channel) => channel.id === data.id);

  if (!foundChannel) return;

  const editedChannelData = {
    ...data,
    name: data.name ?? foundChannel.name,
    targetType: chatTypes.channel,
    users: [...foundChannel.users],
  };

  const updatedChannels = channels.map((channel) => {
    if (channel.id === foundChannel.id) return editedChannelData;
    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: updatedChannels,
    currentChat: data.id === currentChat?.id ? editedChannelData : prev.currentChat,
  }));
};

const sortChannels = (channelsData = []) => {
  const sortedChannels = channelsData.sort((channelA, channelB) => {
    if (!channelA.public && channelB.public) return -1;
    if (channelA.public && !channelB.public) return 1;

    if (channelA.readOnly && !channelB.readOnly) return -1;
    if (!channelA.readOnly && channelB.readOnly) return 1;

    return orderByTimestamp(channelA.id, channelB.id);
  });

  return sortedChannels;
};

const orderByTimestamp = (uuidA, uuidB, asc) => {
  const timeA = uuidA ? getTimestampFromUuidv7(uuidA) : 0;
  const timeB = uuidB ? getTimestampFromUuidv7(uuidB) : 0;

  return asc ? timeA - timeB : timeB - timeA;
};

export const useChatStore = (...args) => useStore(chatStore, ...args);
