import { uuidv7 } from 'uuidv7';
import { getToken } from '@oneecosystem/authenticate';
import { isFunction } from '@veraio/core';
import { notifyStore } from './listeners';
import { showApiError, showError } from 'components';

// WebSocket initialization
export const listeners = new Map();
const maxRetries = 10;
export let webSocket = null;
let fcmToken = '';
let retryCount = 0;

export const connectWebSocket = (fcm) => {
  const authToken = getToken()?.access_token;

  if (!authToken) return;

  if (fcm) fcmToken = fcm;

  const protocols = ['Authorization', authToken, 'X-FCM-Token', encodeURIComponent(fcmToken)];

  webSocket = new WebSocket(apiUrls.chatApiUrl, protocols);

  webSocket.onopen = (e) => {
    console.log('WebSocket connected');

    // Reset the retry count on successful connection
    retryCount = 0;
  };

  webSocket.onclose = () => onWSClose();

  webSocket.onerror = (event) => onWSError(event);

  webSocket.onmessage = (event) => onWSMessage(event.data);
};
// It may be helpful to examine the socket's bufferedAmount attribute before attempting to
// close the connection to determine if any data has yet to be transmitted on the network.
// If this value isn't 0, there's pending data still, so you may wish to wait before closing
// the connection.e
export const disconnectWebSocket = () => {
  webSocket && webSocket.close();
};

export const sendMessage = (messageId, payload, callback) => {
  const correlationId = uuidv7();
  const header = { messageId, correlationId };

  addListener(correlationId, callback);

  !webSocket || webSocket?.readyState !== 1
    ? waitForSocketConnection(() => {
        webSocket.send(JSON.stringify({ header, payload }));
      })
    : webSocket?.readyState === 1 && webSocket.send(JSON.stringify({ header, payload }));
};

const addListener = (requestId, callback) => listeners.set(requestId, callback);

const waitForSocketConnection = (callback) => {
  setTimeout(() => {
    if (webSocket?.readyState === 1) isFunction(callback) && callback();
    else if (webSocket?.readyState === 0) waitForSocketConnection(callback);
  }, 3000);
};

const onWSMessage = (data) => {
  const response = JSON.parse(data);
  if (!response.success && response.message) return showApiError(response);

  const requestId = response.header?.correlationId;
  if (listeners.has(requestId)) {
    if (!response.payload.success && response.payload.message) return showError(response.payload);

    const callback = listeners.get(requestId);
    isFunction(callback) && callback(response.payload);

    listeners.delete(requestId);
  } else notifyStore(response.header?.messageId, response.payload);
};

const onWSClose = () => {
  console.log('WebSocket has disconnected.');
  if (retryCount < maxRetries) {
    retryConnection();
    return;
  }

  listeners.clear();
  disconnectWebSocket();
};

const onWSError = (error) => {
  console.log('WebSocket error', error);
  showError('Cannot connect. Please try again later.');
  disconnectWebSocket();
};

const retryConnection = () => {
  retryCount++;
  const retryDelay = Math.min(1000 * 2 ** retryCount, 30000); // Cap the delay at 30 seconds

  console.log(`Reconnecting in ${retryDelay / 1000} seconds...`);

  setTimeout(() => {
    connectWebSocket();
  }, retryDelay);
};
